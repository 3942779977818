var numeric = function (value, unit) { return Number(value.slice(0, -1 * unit.length)); };

var parseValue = function (value) {
    if (value.endsWith('px'))
        { return { value: value, type: 'px', numeric: numeric(value, 'px') } }
    if (value.endsWith('fr'))
        { return { value: value, type: 'fr', numeric: numeric(value, 'fr') } }
    if (value.endsWith('%'))
        { return { value: value, type: '%', numeric: numeric(value, '%') } }
    if (value === 'auto') { return { value: value, type: 'auto' } }
    return null
};

var parse = function (rule) { return rule.split(' ').map(parseValue); };

var combine = function (rule, tracks) {
    var prevTracks = rule ? rule.split(' ') : [];

    tracks.forEach(function (track, i) {
        if (i > prevTracks.length - 1) {
            throw new Error(
                ("Unable to set size of track index " + i + ", there are only " + (prevTracks.length) + " tracks in the grid layout.")
            )
        }

        prevTracks[i] = track.value
            ? track.value
            : ("" + (track.numeric) + (track.type));
    });

    return prevTracks.join(' ')
};

var getSizeAtTrack = function (index, tracks, gap, end) {
    if ( gap === void 0 ) gap = 0;
    if ( end === void 0 ) end = false;

    var newIndex = end ? index + 1 : index;
    var trackSum = tracks
        .slice(0, newIndex)
        .reduce(function (accum, value) { return accum + value.numeric; }, 0);
    var gapSum = gap ? index * gap : 0;

    return trackSum + gapSum
};

export { combine, getSizeAtTrack, parse };
